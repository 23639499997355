/* Footer.css */
.footer {
    padding: 20px;
    background-color: #2a5d84; 
    color: white;
    text-align: center;
    border-top: 3px solid #499aa6; 
    font-size: 0.9em; 
  }
  
  footer address {
    margin-bottom: 15px;
    font-style: normal;
    line-height: 1.6; 
  }
  