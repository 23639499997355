/* SearchBar.css */
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background-color: #f4f7f9;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-bar input {
  width: 60%;
  padding: 10px 15px;
  margin-right: 10px;
  border: 2px solid #347a87;
  border-radius: 15px;
  font-size: 1em;
  color: #333;
  background-color: #e8eeef;
}

.search-bar button {
  padding: 10px 20px;
  border: none;
  border-radius: 15px;
  background-color: #499aa6;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.search-bar button:hover {
  background-color: #347a87;
  transform: translateY(-2px);
}

/* Tablet devices */
@media (max-width: 768px) {
  .search-bar {
    flex-direction: column; /* Stack elements vertically */
    padding: 20px; /* Increase padding */
  }

  .search-bar input,
  .search-bar button {
    width: 80%; /* Increase width for better usability */
    margin: 10px 0; /* Adjust margin for stacked layout */
  }

  .search-bar input {
    margin-right: 0; /* Remove right margin */
  }
}

/* Mobile devices */
@media (max-width: 480px) {
  .search-bar {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .search-bar input,
  .search-bar button {
    width: 100%; /* Full-width elements for small screens */
  }
}
