/* Profile.css */
.profile-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
}

.login-prompt {
    padding: 20px;
    text-align: center;
}

.profile-nav {
    background-color: #7995B2;
    padding: 10px 0;
    color: white;
    text-align: center;
}

.nav-item {
    color: #fff;
    text-decoration: none;
    margin: 0 15px;
}

.profile-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-card,
.file-card {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-card:hover,
.file-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.profile-pic-upload {
    margin-top: 15px;
}

.profile-pic-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #ddd;
    margin: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-pic-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.upload-btn {
    background-color: #7995B2;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.2s ease;
}

.upload-btn:hover {
    background-color: #627C9B;
}

.files-section {
    min-height: 150px;
    width: 100%;
    padding: 10px;
}

.files-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: auto;
    max-height: 200px;
}

.fab {
    background-color: #7995B2;
    color: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 10px;
    min-width: 50px;
    height: 40px;
}

.fab:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.profile-footer {
    background-color: #7995B2;
    color: white;
    text-align: center;
    padding: 10px 0;
    margin-top: auto;
}

@keyframes hue-shift {

    0%,
    100% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(-10deg);
    }
}

.profile-footer {
    animation: hue-shift 5s infinite alternate ease-in-out;
}

.file-card a {
    color: inherit;
    text-decoration: none;
}