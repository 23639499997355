
.home-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-height: 600px;
}

.home-main-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


@media (max-width: 768px) {
  .home-page-container {
      
  }

  .home-main-content {
     
      padding: 20px;
      box-sizing: border-box; 
  }
}

/* Adjustments for mobile devices */
@media (max-width: 480px) {
  .home-page-container {
      min-height: 500px; /* Adjust minimum height for smaller devices */
  }

  .home-main-content {
      /* Adjust layout or spacing for mobile-friendly interaction */
      padding: 15px;
      box-sizing: border-box;
  }
}
