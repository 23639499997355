/* LoginPage.css - Refined with subtle and professional entry animation */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #347a87;
  /* Preferred background color */
  font-family: 'Poppins', sans-serif;
}

.login-form {
  width: 100%;
  max-width: 360px;
  padding: 40px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-form input,
.login-form button,
.login-form .back-button {
  /* Added .back-button to apply same styles */
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #789abc;
  /* Synchronized border color */
  margin: 10px 0;
  transition: all 0.3s ease;
}

.login-form input {
  width: calc(100% - 30px);
  font-size: 16px;
}

.login-form button,
.login-form .back-button {
  /* Added .back-button to apply same styles */
  background-color: #499aa6;
  /* Button color matched with navbar accent */
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

/* Additional hover effect for the back button if needed */
.login-form .back-button:hover {
  background-color: #347a87;
  /* Matching the hover state of the main button */
}

.login-form button:hover {
  background-color: #347a87;
  /* Darker shade for interaction */
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .login-form {
    padding: 30px 20px;
  }
}

@media (max-width: 480px) {
  .login-form {
    padding: 20px 15px;
  }
}

.login-form .back-button,
.login-form .login-button {
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #789abc;
  background-color: #499aa6;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  flex: 1; /* Allows the buttons to grow and fill the space equally */
  margin: 10px; /* Provides spacing between the buttons */
}

/* Hover effects for both buttons */
.login-form .back-button:hover,
.login-form .login-button:hover {
  background-color: #347a87;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}